import { Container } from "unstated";
import * as api from "../api";
import update from "immutability-helper";
import download from "downloadjs";
import { arrayMove } from "react-sortable-hoc";
import { getSiteType } from "../constants/SiteType";
import history from "../components/History";

class ApplicationsContainer extends Container {
  state = {
    isLoading: false,
    warnings: [],
    warningHeader: "",
    warningFooter: "",
    page2LoadedComponents: 0,
    applications: [],
    currentApplication: null,
    currentApplicationId: "",
    currentApplicationState: "",
    policies: [],
    coverages: [],
    coveragesMap: new Map(),
    coverageLimits: [],
    coverageMax: 0,
    locations: [],
    locationsMap: new Map(),
    locationSites: [],
    undergroundTankAges: [],
    undergroundConstructions: [],
    undergroundCapacities: [],
    undergroundContents: [],
    abovegroundDeductibles: [],
    abovegroundStorageTanks: [],
    abovegroundStorageTanksMap: new Map(),
    abovegroundTankAges: [],
    abovegroundConstructions: [],
    abovegroundCapacities: [],
    abovegroundContents: [],
    undergroundDeductibles: [],
    undergroundPipeAges: [],
    undergroundLeakDetectionTypes: [],
    undergroundPipeLineConstructions: [],
    undergroundStorageTanks: [],
    undergroundStorageTanksMap: new Map(),
    requirementQuestions: [],
    requirementQuestionsMap: new Map(),
    requestedCertificateForFinancialAssurance: null,
    endorsementQuestion1: null,
    tria: null,
    additionalNamedInsured: new Set(),
    additionalNamedInsuredMap: new Map(),
    selectedNamedInsureds: [],
    selectedNamedInsuredsMap: new Map(),
    additionalInsured: new Set(),
    additionalInsuredMap: new Map(),
    entity1: null,
    entity2: null,
    signature: {},
    status: "",
    premium: "",
    triaCost: "",
    assessmentsAndSurcharges: "",
    total: "",
    bindingQuestionsMap: new Map(),
    locationsBindingQuestions: [],
    abovegroundBindingQuestions: [],
    undergroundBindingQuestions: [],
    undergroundBindingQuestionsMap: new Map(),
    bound: false,
    comments: [],
    insuranceFraudWarning: null,
    valuesMap: new Map(),
    isValidating: false,
    error: "",
  };

  initialize = async () => {
    await this.setState({
      page2LoadedComponents: 0,
      applications: [],
      currentApplication: null,
      currentApplicationId: "",
      policies: [],
      coverages: [],
      coveragesMap: new Map(),
      coverageLimits: [],
      coverageMax: 0,
      locations: [],
      locationsMap: new Map(),
      locationSites: [],
      undergroundTankAges: [],
      undergroundConstructions: [],
      undergroundCapacities: [],
      undergroundContents: [],
      abovegroundDeductibles: [],
      abovegroundStorageTanks: [],
      abovegroundStorageTanksMap: new Map(),
      abovegroundTankAges: [],
      abovegroundConstructions: [],
      abovegroundCapacities: [],
      abovegroundContents: [],
      undergroundDeductibles: [],
      undergroundPipeAges: [],
      undergroundLeakDetectionTypes: [],
      undergroundPipeLineConstructions: [],
      undergroundStorageTanks: [],
      undergroundStorageTanksMap: new Map(),
      requirementQuestions: [],
      requirementQuestionsMap: new Map(),
      requestedCertificateForFinancialAssurance: null,
      endorsementQuestion1: null,
      tria: null,
      additionalNamedInsured: new Set(),
      additionalNamedInsuredMap: new Map(),
      selectedNamedInsureds: [],
      selectedNamedInsuredsMap: new Map(),
      additionalInsured: new Set(),
      additionalInsuredMap: new Map(),
      entity1: null,
      entity2: null,
      signature: {},
      status: "",
      premium: "",
      triaCost: "",
      assessmentsAndSurcharges: "",
      total: "",
      bindingQuestionsMap: new Map(),
      locationsBindingQuestions: [],
      abovegroundBindingQuestions: [],
      undergroundBindingQuestions: [],
      undergroundBindingQuestionsMap: new Map(),
      bound: false,
      comments: [],
      valuesMap: new Map(),
      isValidating: false,
      error: "",
    });
  };

  checkResult = async (result) => {
    if (!result) {
      await this.setState({
        warnings: "There was an unexpected problem. Please try again.",
        warningHeader: result?.warningHeader || "Error",
        warningFooter: result?.warningFooter || "",
        isLoading: false,
      });
      return false;
    }

    if (!result?.succeeded) {
      await this.setState({
        warnings:
          result?.warnings && result?.warnings.length > 0
            ? result.warnings
            : ["There was an unexpected problem. Please try again."],
        warningHeader: result?.warningHeader || "Error",
        warningFooter: result?.warningFooter || "",
        isLoading: false,
      });
      return false;
    }
    if (result?.warnings?.length > 0) {
      await this.setState({
        warnings: result?.warnings,
        warningHeader: result?.warningHeader || "Error",
        warningFooter: result?.warningFooter || "",
        isLoading: false,
      });
      return result?.succeeded;
    }

    return true;
  };

  viewApplication = async (id) => {
    this.setState({
      isLoading: true,
    });
    const application = await api.viewApplication(id);
    const applicationData = await api.getApplication(id);
    //  application.underwriterReferralReasons = [
    //    "An item in storage tank schedule that requires referral",
    //    "Coverage limits over $5,000,000",
    //  ];

    if (applicationData.underwriterReferralReasons.length > 0) {
      application.underwriterReferralReasons =
        applicationData.underwriterReferralReasons;
    }
    this.setState({
      currentApplication: application,
    });
  };

  getApplications = async (
    currentPage,
    recordsPerPage,
    orderBy,
    descending,
    showAll
  ) => {
    this.setState({
      isLoading: true,
    });

    const result = await api.getApplications(
      currentPage,
      recordsPerPage,
      orderBy,
      descending,
      showAll
    );

    if (!this.checkResult(result)) {
      return;
    }

    let applications = [];
    let applications2 = [];

    result?.applications?.map((application) => {
      if (application.status === "Requires additional information") {
        applications.push(application);
      } else {
        applications2.push(application);
      }
      return true;
    });

    applications = applications.concat(applications2);

    this.setState({
      applications: applications,
      isLoading: false,
    });

    return result?.totalRecords;
  };

  newApplication = async () => {
    await this.initialize();
    history.push("/application/1");
  };

  continueApplication = async (id) => {
    await this.initialize();
    await this.setState({
      currentApplicationId: id,
    });
    await this.getApplication(id);
    history.push("/application/1");
  };

  getApplicationInformation = async (id) => {
    this.setState({
      isLoading: true,
    });
    const result = await api.getApplication(id);

    if (!this.checkResult(result)) {
      return;
    }

    this.setState({
      isLoading: false,
    });
    return result;
  };
  getApplication = async (id) => {
    this.setState({
      isLoading: true,
    });

    const result = await api.getApplication(id);

    if (!this.checkResult(result)) {
      return;
    }

    await this.setState({
      currentApplication: result,
    });

    let additionalNamedInsured = new Set();
    let additionalNamedInsuredMap = new Map();
    let additionalInsured = new Set();
    let additionalInsuredMap = new Map();

    result.insureds.map((insured) => {
      if (insured.id !== result.namedInsured.id) {
        if (insured.type === "Named") {
          additionalNamedInsured.add(insured.id);
          additionalNamedInsuredMap.set(insured.id, insured);
        } else {
          additionalInsured.add(insured.id);
          additionalInsuredMap.set(insured.id, insured);
        }
      }
      return true;
    });

    await this.setState({
      isLoading: false,
      requestedCertificateForFinancialAssurance:
        result.requestedCertificateForFinancialAssurance === true
          ? "yes"
          : result.requestedCertificateForFinancialAssurance === false
          ? "no"
          : null,
      tria: result.tria === true ? "yes" : result.tria === false ? "no" : null,
      additionalNamedInsured: additionalNamedInsured,
      additionalNamedInsuredMap: additionalNamedInsuredMap,
      additionalInsured: additionalInsured,
      additionalInsuredMap: additionalInsuredMap,
      entity1:
        result.noticeOfCancellationEntity1 ||
        result.noticeOfCancellationEntity1Address
          ? {
              name: result.noticeOfCancellationEntity1,
              mailingAddress: result.noticeOfCancellationEntity1Address,
            }
          : null,
      entity2:
        result.noticeOfCancellationEntity2 ||
        result.noticeOfCancellationEntity2Address
          ? {
              name: result.noticeOfCancellationEntity2,
              mailingAddress: result.noticeOfCancellationEntity2Address,
            }
          : null,
    });
  };

  getApplicationPDF = async (id) => {
    const result = await api.getApplicationPDF(id);

    if (!this.checkResult(result)) {
      return;
    }

    if (result.file && result.fileName && result.mimeType) {
      const data = atob(result.file);
      download(data, result.fileName, result.mimeType);
    }
  };

  getPolicyPDF = async (id) => {
    const result = await api.getPolicyPDF(id);

    if (!this.checkResult(result)) {
      return;
    }

    if (result.file && result.fileName && result.mimeType) {
      const data = atob(result.file);
      download(data, result.fileName, result.mimeType);
    }
  };

  initBindApplication = async () => {
    let result = await api.validateEffectiveDate(
      this.state.currentApplicationId
    );

    if (!this.checkResult(result)) {
      return;
    }

    if (result?.succeeded === false) {
      await this.getApplication(this.state.currentApplicationId);
      history.push("/application/1");
      return false;
    }

    result = await api.getBindingQuestions(this.state.currentApplicationId);

    return true;
  };

  getSelectedNamedInsureds = async () => {
    const result = await api.getSelectedNamedInsureds(
      this.state.currentApplicationId
    );

    if (!this.checkResult(result)) {
      return;
    }

    let selectedNamedInsureds = [];
    const selectedNamedInsuredsMap = new Map();

    result.namedInsureds.map((namedInsured) => {
      selectedNamedInsureds.push(namedInsured.state);
      selectedNamedInsuredsMap.set(namedInsured.state, namedInsured);
      return true;
    });

    this.setState({
      selectedNamedInsureds: selectedNamedInsureds,
      selectedNamedInsuredsMap: selectedNamedInsuredsMap,
    });
  };

  setSelectedNamedInsured = async (state, selectedNamedInsuredId) => {
    const payload = {
      id: this.state.selectedNamedInsuredsMap.get(state).id,
      selectedNamedInsuredId: selectedNamedInsuredId,
      state: state,
    };

    const result = await api.setSelectedNamedInsured(
      this.state.currentApplicationId,
      payload
    );

    if (!this.checkResult(result)) {
      return;
    }

    const selectedNamedInsuredsMap = this.state.selectedNamedInsuredsMap;

    const newSelectedNamedInsuredsMap = update(selectedNamedInsuredsMap, {
      [state]: {
        $merge: {
          id: result.id,
          selectedNamedInsuredId: selectedNamedInsuredId,
        },
      },
    });

    this.setState({
      selectedNamedInsuredsMap: newSelectedNamedInsuredsMap,
    });
  };

  getBindingQuestions = async () => {
    const result = await api.getBindingQuestions(
      this.state.currentApplicationId
    );

    if (!this.checkResult(result)) {
      return;
    }

    const questionsMap = new Map();

    result.states.map((state) => {
      state.questions.map((question) => {
        questionsMap.set(question.bindingQuestionTemplateId, question.label);
        return true;
      });
      return true;
    });

    const bindingQuestionsMap = new Map();
    const locationsBindingQuestions = [];
    const abovegroundBindingQuestions = [];
    const undergroundBindingQuestions = [];

    //locations
    result.states.map((state) => {
      let questionSet = {
        locations: [],
      };

      const locationsMap = new Map();
      const locationQuestions = new Map();

      state.locations.map((location) => {
        locationsMap.set(location.locationId, location.location);

        if (!locationQuestions.get(location.locationId)) {
          locationQuestions.set(location.locationId, []);
        }

        locationQuestions.get(location.locationId).push({
          bindingQuestionId: location.bindingQuestionId,
          bindingQuestionTemplateId: location.bindingQuestionTemplateId,
          value: location.value,
          label: questionsMap.get(location.bindingQuestionTemplateId),
        });
        return true;
      });

      locationQuestions.forEach((value, key) => {
        locationsMap.get(key).questions = value;

        bindingQuestionsMap.set(key, new Map());
        value.map((val) => {
          bindingQuestionsMap.get(key).set(val.bindingQuestionTemplateId, {
            bindingQuestionId: val.bindingQuestionId,
            value: val.value,
          });
          return true;
        });
      });

      if (locationsMap.size) {
        locationsMap.forEach((value, key) => {
          questionSet.locations.push(value);
        });

        locationsBindingQuestions.push(questionSet);
      }
      return true;
    });

    //aboveground
    result.states.map((state) => {
      let questionSet = {
        tanks: [],
      };

      const asTanksMap = new Map();
      const asTankQuestions = new Map();

      state.ast.map((abovegroundStorageTank) => {
        asTanksMap.set(
          abovegroundStorageTank.storageTankId,
          abovegroundStorageTank.storageTank
        );

        if (!asTankQuestions.get(abovegroundStorageTank.storageTankId)) {
          asTankQuestions.set(abovegroundStorageTank.storageTankId, []);
        }

        asTankQuestions.get(abovegroundStorageTank.storageTankId).push({
          bindingQuestionId: abovegroundStorageTank.bindingQuestionId,
          bindingQuestionTemplateId:
            abovegroundStorageTank.bindingQuestionTemplateId,
          value: abovegroundStorageTank.value,
          label: questionsMap.get(
            abovegroundStorageTank.bindingQuestionTemplateId
          ),
        });
        return true;
      });

      asTankQuestions.forEach((value, key) => {
        asTanksMap.get(key).questions = value;

        bindingQuestionsMap.set(key, new Map());
        value.map((val) => {
          bindingQuestionsMap.get(key).set(val.bindingQuestionTemplateId, {
            bindingQuestionId: val.bindingQuestionId,
            value: val.value,
          });
          return true;
        });
      });

      asTanksMap.forEach((value, key) => {
        questionSet.tanks.push(value);
      });

      if (asTanksMap.size) {
        abovegroundBindingQuestions.push(questionSet);
      }

      return true;
    });

    //underground
    result.states.map((state) => {
      let questionSet = {
        tanks: [],
      };

      const usTanksMap = new Map();
      const usTankQuestions = new Map();

      state.ust.map((undergroundStorageTank) => {
        usTanksMap.set(
          undergroundStorageTank.storageTankId,
          undergroundStorageTank.storageTank
        );

        if (!usTankQuestions.get(undergroundStorageTank.storageTankId)) {
          usTankQuestions.set(undergroundStorageTank.storageTankId, []);
        }

        usTankQuestions.get(undergroundStorageTank.storageTankId).push({
          bindingQuestionId: undergroundStorageTank.bindingQuestionId,
          bindingQuestionTemplateId:
            undergroundStorageTank.bindingQuestionTemplateId,
          value: undergroundStorageTank.value,
          label: questionsMap.get(
            undergroundStorageTank.bindingQuestionTemplateId
          ),
        });
        return true;
      });

      usTankQuestions.forEach((value, key) => {
        usTanksMap.get(key).questions = value;

        bindingQuestionsMap.set(key, new Map());

        value.map((val) => {
          bindingQuestionsMap.get(key).set(val.bindingQuestionTemplateId, {
            bindingQuestionId: val.bindingQuestionId,
            value: val.value,
          });
          return true;
        });
      });

      usTanksMap.forEach((value, key) => {
        questionSet.tanks.push(value);
      });

      if (usTanksMap.size) {
        undergroundBindingQuestions.push(questionSet);
      }

      return true;
    });

    await this.setState({
      bindingQuestionsMap: bindingQuestionsMap,
      locationsBindingQuestions: locationsBindingQuestions,
      abovegroundBindingQuestions: abovegroundBindingQuestions,
      undergroundBindingQuestions: undergroundBindingQuestions,
    });
  };

  setBindingQuestion = async (id, bindingQuestionTemplateId, itemId, value) => {
    const payload = {
      id: id,
      bindingQuestionTemplateId: bindingQuestionTemplateId,
      itemId: itemId,
      value: value,
    };

    const result = await api.postBindingQuestion(
      this.state.currentApplicationId,
      payload
    );

    if (!this.checkResult(result)) {
      return;
    }

    const bindingQuestionsMap = this.state.bindingQuestionsMap;

    const newBindingQuestionsMap = update(bindingQuestionsMap, {
      [itemId]: {
        [bindingQuestionTemplateId]: {
          $merge: {
            bindingQuestionId: result.id,
            value: value,
          },
        },
      },
    });

    this.setState({
      bindingQuestionsMap: newBindingQuestionsMap,
    });
  };

  bindApplication = async () => {
    await this.setState({
      isLoading: true,
      isValidating: true,
    });

    let valid = await this.validateBindRequiredFields();

    if (valid) {
      await this.setState({
        isLoading: true,
      });

      let result = await api.validateNamedInsureds(
        this.state.currentApplicationId
      );

      if (!this.checkResult(result)) {
        return;
      }

      result = await api.validateBindingQuestions(
        this.state.currentApplicationId
      );

      if (!this.checkResult(result)) {
        return;
      }

      result = await api.bindApplication(this.state.currentApplicationId);

      if (!this.checkResult(result)) {
        return;
      }

      // Check if any storage tank is in Florida
      await this.getAbovegroundStorageTanks();
      await this.getUndergroundStorageTanks();

      const isAnyTankInFlorida =
        Array.from(this.state.abovegroundStorageTanksMap.values()).some(
          (tank) => tank.location.state === "FL"
        ) ||
        Array.from(this.state.undergroundStorageTanksMap.values()).some(
          (tank) => tank.location.state === "FL"
        );

      if (isAnyTankInFlorida) {
        this.SendEmailForFloridaDomiciledSubmission();
      }

      await this.setState({
        isLoading: false,
        bound: true,
      });
    }
  };

  sendEmailForNewYorkDomiciledSubmission = async () => {
    await api.sendEmailForNewYorkDomiciledSubmission(
      this.state.currentApplicationId
    );
  };

  SendEmailForFloridaDomiciledSubmission = async () => {
    await api.SendEmailForFloridaDomiciledSubmission(
      this.state.currentApplicationId
    );
  };

  validateBindRequiredFields = async () => {
    if (!this.state.isValidating) {
      return;
    }

    await this.setState({
      isValidating: true,
    });

    let pass = true;

    this.state.selectedNamedInsureds.map((selectedNamedInsured) => {
      const sni = this.state.selectedNamedInsuredsMap.get(selectedNamedInsured);
      if (
        sni.selectedNamedInsuredId === "" ||
        sni.selectedNamedInsuredId === "00000000-0000-0000-0000-000000000000"
      ) {
        pass = false;
      }
      return true;
    });

    if (pass) {
      this.state.bindingQuestionsMap.forEach((value) => {
        value.forEach((value) => {
          if (value.value === "") {
            pass = false;
          }
        });
      });
    }

    const signature = this.state.signature;
    if (
      pass &&
      getSiteType() !== "Admin" &&
      (!signature.name || !signature.title || !signature.signature)
    ) {
      pass = false;
    }

    if (!pass) {
      this.setState({
        isLoading: false,
        error: "Please complete all required fields",
      });
      return false;
    } else {
      await this.setState({
        error: "",
        isLoading: false,
        isValidating: false,
      });
      return true;
    }
  };

  getPolicies = async (currentPage, recordsPerPage, orderBy, descending) => {
    this.setState({
      isLoading: true,
    });

    const result = await api.getPolicies(
      currentPage,
      recordsPerPage,
      orderBy,
      descending
    );

    if (!this.checkResult(result)) {
      return;
    }

    this.setState({
      policies: result?.policies || [],
      isLoading: false,
    });

    return result?.totalRecords || 0;
  };

  renewPolicy = async (id) => {
    const result = await api.renewPolicy(id);

    if (!this.checkResult(result)) {
      return;
    }

    this.continueApplication(result.id);
  };

  getComments = async (id) => {
    const result = await api.getComments(id);

    if (!this.checkResult(result)) {
      return;
    }

    this.setState({
      comments: result.threadedComments,
      currentApplicationId: id,
    });
  };

  // Applications Page 1 -------------------------------------------------------------------
  submitApplication = async (data) => {
    this.setState({
      isLoading: true,
    });

    const usEffectivaDateFormat = data.dateEffectiveUtc.toLocaleDateString(
      "en-US",
      {
        month: "2-digit",
        day: "2-digit",
        year: "numeric",
      }
    );

    const [month, day, year] = usEffectivaDateFormat.split("/");

    const formattedEffectiveDate = `${year}-${month}-${day}T00:00:00.000Z`;

    const usExpirationDateFormat = data.dateExpirationUtc.toLocaleDateString(
      "en-US",
      {
        month: "2-digit",
        day: "2-digit",
        year: "numeric",
      }
    );

    const [month2, day2, year2] = usExpirationDateFormat.split("/");

    const formattedExpirationDate = `${year2}-${month2}-${day2}T00:00:00.000Z`;

    const payload = {
      name: data.name,
      firstName: data.firstName,
      lastName: data.lastName,
      phoneNumber: data.phoneNumber,
      email: data.email,
      addressLine1: data.addressLine1,
      addressLine2: data.addressLine2,
      city: data.city,
      state: data.state,
      postalCode: data.postalCode,
      dateSubmittedUtc: new Date(),
      dateEffectiveUtc: formattedEffectiveDate,
      dateExpirationUtc: formattedExpirationDate,
      isMultiYearTerm: data.isMultiYearTerm,
      acceptedNonIndividualPolicyDisclaimer:
        data.acceptedNonIndividualPolicyDisclaimer,
    };

    let result;

    if (this.state.currentApplicationId) {
      result = await api.updateApplication(
        this.state.currentApplicationId,
        payload
      );

      if (!this.checkResult(result)) {
        return;
      }
    } else {
      result = await api.submitApplication(payload);

      if (!this.checkResult(result)) {
        return;
      }

      await this.setState({
        currentApplicationId: result?.id,
      });
    }

    await this.getApplication(this.state.currentApplicationId);

    this.setState({
      isLoading: false,
    });

    return result;
  };

  // Applications Page 2 -------------------------------------------------------------------
  getLocationSites = async () => {
    if (this.state.locationSites.length > 0) {
      return;
    }

    const result = await api.getLocationSites();

    if (!this.checkResult(result)) {
      return;
    }

    const valuesMap = this.state.valuesMap;
    let newValuesMap = valuesMap;

    result?.items?.map((item) => {
      newValuesMap = update(newValuesMap, { $add: [[item.id, item.name]] });
      return true;
    });

    this.setState({
      locationSites: result?.items || [],
      valuesMap: newValuesMap,
    });
  };

  getTankAges = async () => {
    if (
      this.state.abovegroundTankAges.length > 0 &&
      this.state.undergroundTankAges.length > 0
    ) {
      return;
    }

    const abovegroundTankAges = await api.getAbovegroundTankAges();

    if (!this.checkResult(abovegroundTankAges)) {
      return;
    }

    const undergroundTankAges = await api.getUndergroundTankAges();

    if (!this.checkResult(abovegroundTankAges)) {
      return;
    }

    const valuesMap = this.state.valuesMap;
    let newValuesMap = valuesMap;

    abovegroundTankAges.items.map((item) => {
      newValuesMap = update(newValuesMap, { $add: [[item.id, item.name]] });
      return true;
    });

    undergroundTankAges.items.map((item) => {
      newValuesMap = update(newValuesMap, { $add: [[item.id, item.name]] });
      return true;
    });

    this.setState({
      abovegroundTankAges: abovegroundTankAges.items,
      undergroundTankAges: undergroundTankAges.items,
      valuesMap: newValuesMap,
    });
  };

  getConstructions = async () => {
    if (
      this.state.abovegroundConstructions.length > 0 &&
      this.state.undergroundConstructions.length > 0
    ) {
      return;
    }

    const abovegroundConstructions = await api.getAbovegroundConstructions();

    if (!this.checkResult(abovegroundConstructions)) {
      return;
    }

    const undergroundConstructions = await api.getUndergroundConstructions();

    if (!this.checkResult(undergroundConstructions)) {
      return;
    }

    const valuesMap = this.state.valuesMap;
    let newValuesMap = valuesMap;

    abovegroundConstructions.items.map((item) => {
      newValuesMap = update(newValuesMap, { $add: [[item.id, item.name]] });
      return true;
    });

    undergroundConstructions.items.map((item) => {
      newValuesMap = update(newValuesMap, { $add: [[item.id, item.name]] });
      return true;
    });

    this.setState({
      abovegroundConstructions: abovegroundConstructions.items,
      undergroundConstructions: undergroundConstructions.items,
      valuesMap: newValuesMap,
    });
  };

  getCapacities = async () => {
    if (
      this.state.abovegroundCapacities.length > 0 &&
      this.state.undergroundCapacities.length > 0
    ) {
      return;
    }

    const abovegroundCapacities = await api.getAbovegroundCapacities();

    if (!this.checkResult(abovegroundCapacities)) {
      return;
    }

    const undergroundCapacities = await api.getUndergroundCapacities();

    if (!this.checkResult(undergroundCapacities)) {
      return;
    }

    const valuesMap = this.state.valuesMap;
    let newValuesMap = valuesMap;

    abovegroundCapacities.items.map((item) => {
      newValuesMap = update(newValuesMap, { $add: [[item.id, item.name]] });
      return true;
    });

    undergroundCapacities.items.map((item) => {
      newValuesMap = update(newValuesMap, { $add: [[item.id, item.name]] });
      return true;
    });

    this.setState({
      abovegroundCapacities: abovegroundCapacities.items,
      undergroundCapacities: undergroundCapacities.items,
      valuesMap: newValuesMap,
    });
  };

  getContents = async () => {
    if (
      this.state.abovegroundContents.length > 0 &&
      this.state.undergroundContents.length > 0
    ) {
      return;
    }

    const abovegroundContents = await api.getAbovegroundContents();

    if (!this.checkResult(abovegroundContents)) {
      return;
    }

    const undergroundContents = await api.getUndergroundContents();

    if (!this.checkResult(undergroundContents)) {
      return;
    }

    const valuesMap = this.state.valuesMap;
    let newValuesMap = valuesMap;

    abovegroundContents.items.map((item) => {
      newValuesMap = update(newValuesMap, { $add: [[item.id, item.name]] });
      return true;
    });

    undergroundContents.items.map((item) => {
      newValuesMap = update(newValuesMap, { $add: [[item.id, item.name]] });
      return true;
    });

    this.setState({
      abovegroundContents: abovegroundContents.items,
      undergroundContents: undergroundContents.items,
      valuesMap: newValuesMap,
    });
  };

  getDeductibles = async () => {
    if (
      this.state.abovegroundDeductibles.length > 0 &&
      this.state.undergroundDeductibles.length > 0
    ) {
      return;
    }

    const abovegroundDeductibles = await api.getAbovegroundDeductions();

    if (!this.checkResult(abovegroundDeductibles)) {
      return;
    }

    const undergroundDeductibles = await api.getUndergroundDeductibles();

    if (!this.checkResult(undergroundDeductibles)) {
      return;
    }

    const valuesMap = this.state.valuesMap;
    let newValuesMap = valuesMap;

    abovegroundDeductibles.items.map((item) => {
      newValuesMap = update(newValuesMap, { $add: [[item.id, item.name]] });
      return true;
    });

    undergroundDeductibles.items.map((item) => {
      newValuesMap = update(newValuesMap, { $add: [[item.id, item.name]] });
      return true;
    });

    this.setState({
      abovegroundDeductibles: abovegroundDeductibles.items,
      undergroundDeductibles: undergroundDeductibles.items,
      valuesMap: newValuesMap,
    });
  };

  getPipeAges = async () => {
    if (this.state.undergroundPipeAges.length > 0) {
      return;
    }

    const undergroundPipeAges = await api.getUndergroundPipeAges();

    if (!this.checkResult(undergroundPipeAges)) {
      return;
    }

    const valuesMap = this.state.valuesMap;
    let newValuesMap = valuesMap;

    undergroundPipeAges.items.map((item) => {
      newValuesMap = update(newValuesMap, { $add: [[item.id, item.name]] });
      return true;
    });

    this.setState({
      undergroundPipeAges: undergroundPipeAges.items,
      valuesMap: newValuesMap,
    });
  };

  getUndergroundLeakDetectionTypes = async () => {
    if (this.state.undergroundLeakDetectionTypes.length > 0) {
      return;
    }

    const undergroundLeakDetectionTypes =
      await api.getUndergroundLeakDetectionTypes();

    if (!this.checkResult(undergroundLeakDetectionTypes)) {
      return;
    }

    const valuesMap = this.state.valuesMap;
    let newValuesMap = valuesMap;

    undergroundLeakDetectionTypes.items.map((item) => {
      newValuesMap = update(newValuesMap, { $add: [[item.id, item.name]] });
      return true;
    });

    this.setState({
      undergroundLeakDetectionTypes: undergroundLeakDetectionTypes.items,
      valuesMap: newValuesMap,
    });
  };

  getUndergroundPipeLineConstructions = async () => {
    if (this.state.undergroundPipeLineConstructions.length > 0) {
      return;
    }

    const undergroundPipeLineConstructions =
      await api.getUndergroundPipeLineConstructions();

    if (!this.checkResult(undergroundPipeLineConstructions)) {
      return;
    }

    const valuesMap = this.state.valuesMap;
    let newValuesMap = valuesMap;

    undergroundPipeLineConstructions.items.map((item) => {
      newValuesMap = update(newValuesMap, { $add: [[item.id, item.name]] });
      return true;
    });

    this.setState({
      undergroundPipeLineConstructions: undergroundPipeLineConstructions.items,
      valuesMap: newValuesMap,
    });
  };

  checkPage2LoadedComponents = () => {
    if (this.state.page2LoadedComponents === 4) {
      this.setState({
        isLoading: false,
      });
    }
  };

  //coverages
  getCoverages = async () => {
    this.setState({
      isLoading: true,
    });

    const result = await api.getCoverages(this.state.currentApplicationId);

    if (!this.checkResult(result)) {
      return;
    }

    let coverages = [];
    const coveragesMap = new Map();

    result.coverages.map((coverage) => {
      coverages.push(coverage.templateId);
      coveragesMap.set(coverage.templateId, coverage);
      return true;
    });

    await this.setState({
      page2LoadedComponents: this.state.page2LoadedComponents + 1,
      coverages: coverages,
      coveragesMap: coveragesMap,
      isLoading: false,
    });

    result.coverages.map(async (coverage) => {
      if (coverage.field1Type === "Bool" && coverage.boolNoOnly === true) {
        await this.updateCoverages({
          id: coverage.id,
          templateId: coverage.templateId,
          field1Value: "false",
        });
      }

      if (coverage.field1Type === "Bool" && coverage.boolYesOnly === true) {
        await this.updateCoverages({
          id: coverage.id,
          templateId: coverage.templateId,
          field1Value: "true",
        });
      }
      return true;
    });

    this.checkPage2LoadedComponents();
  };

  getCoverageLimits = async () => {
    if (this.state.coverageLimits > 0) {
      return;
    }

    const result = await api.getCoverageLimits();

    if (!this.checkResult(result)) {
      return;
    }

    const valuesMap = this.state.valuesMap;
    let newValuesMap = valuesMap;

    result.coverageLimits.map((item) => {
      newValuesMap = update(newValuesMap, { $add: [[item.id, item.name]] });
      return true;
    });

    await this.setState({
      coverageLimits: result.coverageLimits,
      valuesMap: newValuesMap,
    });
  };

  updateCoverages = async (data) => {
    this.setState({
      isLoading: true,
    });

    const coveragesMap = this.state.coveragesMap;
    let newCoveragesMap = coveragesMap;

    let payload = {
      id: this.state.coveragesMap.get(data.templateId).id,
      templateId: data.templateId,
      field1Value: data.field1Value,
    };

    let result = await api.updateCoverage(
      this.state.currentApplicationId,
      payload
    );

    if (!this.checkResult(result)) {
      return;
    }

    newCoveragesMap = update(newCoveragesMap, {
      [data.templateId]: {
        $set: {
          ...data,
          id: result.id,
          field1Value: data.field1Value,
        },
      },
    });

    await this.setState({
      coveragesMap: newCoveragesMap,
      isLoading: false,
    });

    this.validatePage2RequiredFields();

    this.state.coverages.map(async (coverage) => {
      coverage = this.state.coveragesMap.get(coverage);

      if (
        data.field1Type === "Dropdown" &&
        coverage.field1Type === "Dropdown" &&
        coverage.id !== result.id
      ) {
        this.setState({
          isLoading: true,
        });

        payload = {
          id: this.state.coveragesMap.get(coverage.templateId).id,
          templateId: coverage.templateId,
          field1Value: data.field1Value,
        };

        result = await api.updateCoverage(
          this.state.currentApplicationId,
          payload
        );

        if (!this.checkResult(result)) {
          return;
        }

        newCoveragesMap = update(newCoveragesMap, {
          [coverage.templateId]: {
            $set: {
              ...coverage,
              id: result.id,
              field1Value: data.field1Value,
            },
          },
        });

        await this.setState({
          coveragesMap: newCoveragesMap,
          isLoading: false,
        });

        this.validatePage2RequiredFields();
      }
      return true;
    });

    this.getTotal();
  };

  //locations
  getLocations = async () => {
    this.setState({
      isLoading: true,
    });

    const result = await api.getLocations(this.state.currentApplicationId);

    if (!this.checkResult(result)) {
      return;
    }

    let locations = [];
    const locationsMap = new Map();

    result.locations.map((location) => {
      locationsMap.set(location.id, location);
      locations.push(location.id);
      return true;
    });

    await this.setState({
      page2LoadedComponents: this.state.page2LoadedComponents + 1,
      locations: locations,
      locationsMap: locationsMap,
    });

    this.checkPage2LoadedComponents();
    this.validatePage2RequiredFields();
  };

  addLocation = async () => {
    this.setState({
      isLoading: true,
    });

    const payload = {
      applicationId: this.setState.currentApplicationId,
      siteUse: "None",
      otherSiteUse: "",
      address: "",
      city: "",
      state: "",
      postalCode: "",
    };

    const result = await api.addLocation(
      this.state.currentApplicationId,
      payload
    );

    if (!this.checkResult(result)) {
      return;
    }

    await this.getLocations();

    this.setState({
      isLoading: false,
    });

    this.getTotal();
  };

  updateLocation = async (data) => {
    this.setState({
      isLoading: true,
    });

    const locationsMap = this.state.locationsMap;

    const newLocationsMap = update(locationsMap, {
      [data.id]: {
        $set: {
          siteUse: data.siteUse,
          otherSiteUse: data.otherSiteUse,
          address: data.address,
          city: data.city,
          state: data.state,
          postalCode: data.postalCode,
        },
      },
    });

    await this.setState({
      locationsMap: newLocationsMap,
    });

    const payload = {
      applicationId: this.state.currentApplicationId,
      siteUse: data.siteUse,
      otherSiteUse: data.otherSiteUse,
      address: data.address,
      city: data.city,
      state: data.state,
      postalCode: data.postalCode,
    };

    const result = await api.updateLocation(data.id, payload);

    if (!this.checkResult(result)) {
      return;
    }

    this.setState({
      isLoading: false,
    });

    this.validatePage2RequiredFields();
    this.getTotal();
  };

  updateLocationsOrder = async (oldIndex, newIndex) => {
    this.setState({
      isLoading: true,
    });

    const newLocations = arrayMove(this.state.locations, oldIndex, newIndex);

    this.setState({
      locations: newLocations,
    });

    const result = await api.reorderLocations(newLocations);

    if (!this.checkResult(result)) {
      return;
    }

    this.setState({
      isLoading: false,
    });
  };

  uploadLocationsFile = async (file) => {
    this.setState({
      isLoading: true,
    });

    const result = await api.uploadLocationsFile(
      this.state.currentApplicationId,
      file
    );

    if (!this.checkResult(result)) {
      return;
    }

    await this.getLocations();

    this.setState({
      isLoading: false,
    });

    this.getTotal();
  };

  deleteLocations = async (ids) => {
    this.setState({
      isLoading: true,
    });

    const result = await api.deleteLocations(
      this.state.currentApplicationId,
      ids
    );

    if (!this.checkResult(result)) {
      return;
    }

    this.getLocations();
    this.getTotal();

    this.setState({
      isLoading: false,
    });
  };

  //aboveground storage tanks
  getAbovegroundStorageTanks = async () => {
    await this.setState({
      isLoading: true,
    });

    const result = await api.getAbovegroundStorageTanks(
      this.state.currentApplicationId
    );

    if (!this.checkResult(result)) {
      return;
    }

    let abovegroundStorageTanks = [];
    const abovegroundStorageTanksMap = new Map();

    result.abovegroundStorageTanks.map((abovegroundStorageTank) => {
      abovegroundStorageTanksMap.set(
        abovegroundStorageTank.id,
        abovegroundStorageTank
      );
      abovegroundStorageTanks.push(abovegroundStorageTank.id);

      const locationId = abovegroundStorageTank.location
        ? abovegroundStorageTank.location.id
        : "None";
      const retroactiveDateOnExistingPolicyUtc =
        abovegroundStorageTank.retroactiveDateOnExistingPolicyUtc
          ? new Date(abovegroundStorageTank.retroactiveDateOnExistingPolicyUtc)
          : null;

      abovegroundStorageTanksMap.get(abovegroundStorageTank.id).locationId =
        locationId;
      abovegroundStorageTanksMap.get(
        abovegroundStorageTank.id
      ).retroactiveDateOnExistingPolicyUtc = retroactiveDateOnExistingPolicyUtc;

      return true;
    });

    await this.setState({
      page2LoadedComponents: this.state.page2LoadedComponents + 1,
      abovegroundStorageTanks: abovegroundStorageTanks,
      abovegroundStorageTanksMap: abovegroundStorageTanksMap,
    });

    this.checkPage2LoadedComponents();
    this.validatePage2RequiredFields();
  };

  addAbovegroundStorageTank = async () => {
    this.setState({
      isLoading: true,
    });

    const payload = {};

    let result = await api.addAbovegroundStorageTank(
      this.state.currentApplicationId,
      payload
    );

    if (!this.checkResult(result)) {
      return;
    }

    await this.getAbovegroundStorageTanks();

    this.setState({
      isLoading: false,
    });

    this.getTotal();
  };

  updateAbovegroundStorageTank = async (data) => {
    this.setState({
      isLoading: true,
    });

    const abovegroundStorageTanksMap = this.state.abovegroundStorageTanksMap;

    const newAbovegroundStorageTanksMap = update(abovegroundStorageTanksMap, {
      [data.id]: {
        $set: {
          locationId: data.locationId,
          tankAgeInNumber: data.tankAgeInNumber,
          construction: data.construction,
          otherConstruction: data.otherConstruction,
          capacityInNumber: data.capacityInNumber,
          updateUndergroundStorageTank: data.updateUndergroundStorageTank,
          contents: data.contents,
          otherContents: data.otherContents,
          leakDetection: data.leakDetection,
          secondaryContainment: data.secondaryContainment,
          retroactiveDateOnExistingPolicyUtc:
            data.retroactiveDateOnExistingPolicyUtc,
          deductible: data.deductible,
        },
      },
    });

    await this.setState({
      abovegroundStorageTanksMap: newAbovegroundStorageTanksMap,
    });

    const payload = {
      applicationId: this.state.currentApplicationId,
      locationId: data.locationId !== "None" ? data.locationId : null,
      tankAgeInNumber: data.tankAgeInNumber
        ? parseInt(data.tankAgeInNumber)
        : 0,
      construction: data.construction,
      otherConstruction: data.otherConstruction,
      capacityInNumber: data.capacityInNumber
        ? parseInt(data.capacityInNumber)
        : 0,
      contents: data.contents,
      otherContents: data.otherContents,
      leakDetection: data.leakDetection,
      secondaryContainment: data.secondaryContainment,
      retroactiveDateOnExistingPolicyUtc:
        data.retroactiveDateOnExistingPolicyUtc,
      deductible: data.deductible,
    };

    const result = await api.updateAboveGroundStorageTank(data.id, payload);

    if (!this.checkResult(result)) {
      return;
    }

    this.setState({
      isLoading: false,
    });

    this.validatePage2RequiredFields();
    this.getTotal();
  };

  uploadAbovegroundStorageTankFile = async (file) => {
    this.setState({
      isLoading: true,
    });

    const result = await api.uploadAbovegroundStorageTankFile(
      this.state.currentApplicationId,
      file
    );

    if (!this.checkResult(result)) {
      return;
    }

    await this.getAbovegroundStorageTanks();

    this.setState({
      isLoading: false,
    });
  };

  deleteAbovegroundStorageTanks = async (ids) => {
    this.setState({
      isLoading: true,
    });

    const result = await api.deleteAbovegroundStorageTanks(
      this.state.currentApplicationId,
      ids
    );

    if (!this.checkResult(result)) {
      return;
    }

    this.getAbovegroundStorageTanks();
    this.getTotal();

    this.setState({
      isLoading: false,
    });
  };

  cloneAbovegroundStorageTank = async (id) => {
    this.setState({
      isLoading: true,
    });

    const abovegroundStorageTank =
      this.state.abovegroundStorageTanksMap.get(id);

    const payload = {
      locationId:
        abovegroundStorageTank.locationId === "None"
          ? null
          : abovegroundStorageTank.locationId,
      tankAgeInNumber: abovegroundStorageTank.tankAgeInNumber
        ? abovegroundStorageTank.tankAgeInNumber
        : 0,
      construction: abovegroundStorageTank.construction,
      otherConstruction: abovegroundStorageTank.otherConstruction
        ? abovegroundStorageTank.otherConstruction
        : "",
      capacityInNumber: abovegroundStorageTank.capacityInNumber
        ? abovegroundStorageTank.capacityInNumber
        : 0,
      contents: abovegroundStorageTank.contents,
      otherContents: abovegroundStorageTank.otherContents
        ? abovegroundStorageTank.otherContents
        : "",
      leakDetection:
        abovegroundStorageTank.leakDetection === true ||
        abovegroundStorageTank.leakDetection === "yes"
          ? "yes"
          : abovegroundStorageTank.leakDetection === false ||
            abovegroundStorageTank.leakDetection === "no"
          ? "no"
          : null,
      secondaryContainment:
        abovegroundStorageTank.secondaryContainment === true ||
        abovegroundStorageTank.secondaryContainment === "yes"
          ? "yes"
          : abovegroundStorageTank.secondaryContainment === false ||
            abovegroundStorageTank.secondaryContainment === "no"
          ? "no"
          : null,
      retroactiveDateOnExistingPolicyUtc:
        abovegroundStorageTank.retroactiveDateOnExistingPolicyUtc,
      deductible: abovegroundStorageTank.deductible,
    };

    await api.addAbovegroundStorageTank(
      this.state.currentApplicationId,
      payload
    );

    this.getAbovegroundStorageTanks();
    this.getTotal();

    this.setState({
      isLoading: false,
    });
  };

  updateAbovegroundStorageTankOrder = async (oldIndex, newIndex) => {
    this.setState({
      isLoading: true,
    });

    const newAbovegroundStorageTanks = arrayMove(
      this.state.abovegroundStorageTanks,
      oldIndex,
      newIndex
    );

    this.setState({
      abovegroundStorageTanks: newAbovegroundStorageTanks,
    });

    const result = await api.reorderAbovegroundStorageTanks(
      newAbovegroundStorageTanks
    );

    if (!this.checkResult(result)) {
      return;
    }

    this.setState({
      isLoading: false,
    });
  };

  //underground storage tanks
  getUndergroundStorageTanks = async () => {
    await this.setState({
      isLoading: true,
    });

    const result = await api.getUndergroundStorageTanks(
      this.state.currentApplicationId
    );

    if (!this.checkResult(result)) {
      return;
    }

    let undergroundStorageTanks = [];
    const undergroundStorageTanksMap = new Map();

    result.undergroundStorageTanks.map((undergroundStorageTank) => {
      undergroundStorageTanks.push(undergroundStorageTank.id);
      undergroundStorageTanksMap.set(
        undergroundStorageTank.id,
        undergroundStorageTank
      );

      const locationId = undergroundStorageTank.location
        ? undergroundStorageTank.location.id
        : "None";

      const retroactiveDateOnExistingPolicyUtc =
        undergroundStorageTank.retroactiveDateOnExistingPolicyUtc
          ? new Date(undergroundStorageTank.retroactiveDateOnExistingPolicyUtc)
          : null;

      undergroundStorageTanksMap.get(undergroundStorageTank.id).locationId =
        locationId;
      undergroundStorageTanksMap.get(
        undergroundStorageTank.id
      ).retroactiveDateOnExistingPolicyUtc = retroactiveDateOnExistingPolicyUtc;

      return true;
    });

    await this.setState({
      page2LoadedComponents: this.state.page2LoadedComponents + 1,
      undergroundStorageTanks: undergroundStorageTanks,
      undergroundStorageTanksMap: undergroundStorageTanksMap,
    });

    this.checkPage2LoadedComponents();
    this.validatePage2RequiredFields();
  };

  addUndergroundStorageTank = async () => {
    this.setState({
      isLoading: true,
    });

    const payload = {};

    let result = await api.addUndergroundStorageTank(
      this.state.currentApplicationId,
      payload
    );

    if (!this.checkResult(result)) {
      return;
    }

    result = await this.getUndergroundStorageTanks();

    this.setState({
      isLoading: false,
    });

    this.getTotal();
  };

  updateUndergroundStorageTank = async (data) => {
    this.setState({
      isLoading: true,
    });

    const undergroundStorageTanksMap = this.state.undergroundStorageTanksMap;

    const newUndergroundStorageTanksMap = update(undergroundStorageTanksMap, {
      [data.id]: {
        $set: {
          locationId: data.locationId,
          tankAgeInNumber: data.tankAgeInNumber,
          construction: data.construction,
          otherConstruction: data.otherConstruction,
          capacityInNumber: data.capacityInNumber,
          contents: data.contents,
          otherContents: data.otherContents,
          typeOfLeakDetection: data.typeOfLeakDetection,
          otherTypeOfLeakDetection: data.otherTypeOfLeakDetection,
          pipeLeakDetection: data.pipeLeakDetection,
          retroactiveDateOnExistingPolicyUtc:
            data.retroactiveDateOnExistingPolicyUtc,
          deductible: data.deductible,
          pipeLineConstruction: data.pipeLineConstruction,
          pipeAgeInNumber: data.pipeAgeInNumber,
          tankTightnessTestPassed: data.tankTightnessTestPassed,
        },
      },
    });

    await this.setState({
      undergroundStorageTanksMap: newUndergroundStorageTanksMap,
    });

    const payload = {
      applicationId: this.state.currentApplicationId,
      locationId: data.locationId !== "None" ? data.locationId : null,
      tankAgeInNumber: data.tankAgeInNumber
        ? parseInt(data.tankAgeInNumber)
        : 0,
      construction: data.construction,
      otherConstruction: data.otherConstruction,
      capacityInNumber: data.capacityInNumber
        ? parseInt(data.capacityInNumber)
        : 0,
      updateUndergroundStorageTank: data.updateUndergroundStorageTank,
      contents: data.contents,
      otherContents: data.otherContents,
      typeOfLeakDetection: data.typeOfLeakDetection,
      otherTypeOfLeakDetection: data.otherTypeOfLeakDetection,
      pipeLeakDetection: data.pipeLeakDetection,
      retroactiveDateOnExistingPolicyUtc:
        data.retroactiveDateOnExistingPolicyUtc,
      deductible: data.deductible,
      pipeLineConstruction: data.pipeLineConstruction,
      pipeAgeInNumber: data.pipeAgeInNumber
        ? parseInt(data.pipeAgeInNumber)
        : 0,
      tankTightnessTestPassed: data.tankTightnessTestPassed,
    };

    const result = await api.updateUndergroundStorageTank(data.id, payload);

    if (!this.checkResult(result)) {
      return;
    }

    this.setState({
      isLoading: false,
    });

    this.validatePage2RequiredFields();
    this.getTotal();
  };

  uploadUndergroundStorageTankFile = async (file) => {
    this.setState({
      isLoading: true,
    });

    const result = await api.uploadUndergroundStorageTankFile(
      this.state.currentApplicationId,
      file
    );

    if (!this.checkResult(result)) {
      return;
    }

    await this.getUndergroundStorageTanks();

    this.setState({
      isLoading: false,
    });

    this.getTotal();
  };

  deleteUndergroundStorageTanks = async (ids) => {
    this.setState({
      isLoading: true,
    });

    const result = await api.deleteUndergroundStorageTanks(
      this.state.currentApplicationId,
      ids
    );

    if (!this.checkResult(result)) {
      return;
    }

    this.getUndergroundStorageTanks();
    this.getTotal();

    this.setState({
      isLoading: false,
    });
  };

  cloneUndergroundStorageTank = async (id) => {
    this.setState({
      isLoading: true,
    });

    const undergroundStorageTank =
      this.state.undergroundStorageTanksMap.get(id);

    const payload = {
      locationId:
        undergroundStorageTank.locationId === "None"
          ? null
          : undergroundStorageTank.locationId,
      tankAgeInNumber: undergroundStorageTank.tankAgeInNumber
        ? undergroundStorageTank.tankAgeInNumber
        : 0,
      construction: undergroundStorageTank.construction,
      otherConstruction: undergroundStorageTank.otherConstruction,
      capacityInNumber: undergroundStorageTank.capacityInNumber
        ? undergroundStorageTank.capacityInNumber
        : 0,
      contents: undergroundStorageTank.contents,
      otherContents: undergroundStorageTank.otherContents,
      typeOfLeakDetection: undergroundStorageTank.typeOfLeakDetection,
      otherTypeOfLeakDetection: undergroundStorageTank.otherTypeOfLeakDetection,
      retroactiveDateOnExistingPolicyUtc:
        undergroundStorageTank.retroactiveDateOnExistingPolicyUtc,
      deductible: undergroundStorageTank.deductible,
      tankTightnessTestPassed:
        undergroundStorageTank.tankTightnessTestPassed === true ||
        undergroundStorageTank.tankTightnessTestPassed === "yes"
          ? "yes"
          : undergroundStorageTank.tankTightnessTestPassed === false ||
            undergroundStorageTank.tankTightnessTestPassed === "no"
          ? "no"
          : null,
      pipeLineConstruction: undergroundStorageTank.pipeLineConstruction,
      pipeAgeInNumber: undergroundStorageTank.pipeAgeInNumber
        ? undergroundStorageTank.pipeAgeInNumber
        : 0,
      pipeLeakDetection:
        undergroundStorageTank.pipeLeakDetection === true ||
        undergroundStorageTank.pipeLeakDetection === "yes"
          ? "yes"
          : undergroundStorageTank.pipeLeakDetection === false ||
            undergroundStorageTank.pipeLeakDetection === "no"
          ? "no"
          : null,
    };

    await api.addUndergroundStorageTank(
      this.state.currentApplicationId,
      payload
    );

    this.getUndergroundStorageTanks();
    this.getTotal();

    this.setState({
      isLoading: false,
    });
  };

  updateUndergroundStorageTankOrder = async (oldIndex, newIndex) => {
    this.setState({
      isLoading: true,
    });

    const newUndergroundStorageTanks = arrayMove(
      this.state.undergroundStorageTanks,
      oldIndex,
      newIndex
    );

    this.setState({
      undergroundStorageTanks: newUndergroundStorageTanks,
    });

    const result = await api.reorderUndergroundStorageTanks(
      newUndergroundStorageTanks
    );

    if (!this.checkResult(result)) {
      return;
    }

    this.setState({
      isLoading: false,
    });
  };

  //submit Page 2
  validatePage2RequiredFields = async () => {
    if (!this.state.isValidating) {
      return;
    }

    let pass = true;

    this.state.coverages.map((coverage) => {
      if (!this.state.coveragesMap.get(coverage).field1Value) {
        pass = false;
      }
      return true;
    });

    if (pass) {
      this.state.locations.map((location) => {
        location = this.state.locationsMap.get(location);
        if (
          !location.siteUse ||
          location.siteUse === "None" ||
          (location.siteUse === "Other" && !location.otherSiteUse) ||
          !location.address ||
          !location.city ||
          !location.state ||
          !location.postalCode
        ) {
          pass = false;
        }

        return true;
      });
    }

    if (pass) {
      this.state.abovegroundStorageTanks.map((abovegroundStorageTank) => {
        abovegroundStorageTank = this.state.abovegroundStorageTanksMap.get(
          abovegroundStorageTank
        );
        if (
          !abovegroundStorageTank.locationId ||
          abovegroundStorageTank.locationId === "None" ||
          !abovegroundStorageTank.tankAgeInNumber ||
          abovegroundStorageTank.tankAgeInNumber === "0" ||
          !abovegroundStorageTank.construction ||
          abovegroundStorageTank.construction === "None" ||
          (abovegroundStorageTank.construction === "Other" &&
            !abovegroundStorageTank.otherConstruction) ||
          !abovegroundStorageTank.capacityInNumber ||
          abovegroundStorageTank.capacityInNumber === "0" ||
          !abovegroundStorageTank.contents ||
          abovegroundStorageTank.contents === "None" ||
          (abovegroundStorageTank.contents === "Other" &&
            !abovegroundStorageTank.otherContents) ||
          !abovegroundStorageTank.leakDetection ||
          !abovegroundStorageTank.secondaryContainment ||
          !abovegroundStorageTank.retroactiveDateOnExistingPolicyUtc ||
          abovegroundStorageTank.deductible === "None" ||
          !abovegroundStorageTank.deductible
        ) {
          pass = false;
        }

        return true;
      });
    }

    if (pass) {
      this.state.undergroundStorageTanks.map((undergroundStorageTank) => {
        undergroundStorageTank = this.state.undergroundStorageTanksMap.get(
          undergroundStorageTank
        );
        if (
          !undergroundStorageTank.locationId ||
          undergroundStorageTank.locationId === "None" ||
          !undergroundStorageTank.tankAgeInNumber ||
          undergroundStorageTank.tankAgeInNumber === "0" ||
          undergroundStorageTank.construction === "None" ||
          !undergroundStorageTank.construction ||
          (undergroundStorageTank.construction === "Other" &&
            !undergroundStorageTank.otherConstruction) ||
          !undergroundStorageTank.capacityInNumber ||
          undergroundStorageTank.capacityInNumber === "0" ||
          undergroundStorageTank.contents === "None" ||
          !undergroundStorageTank.contents ||
          (undergroundStorageTank.contents === "Other" &&
            !undergroundStorageTank.otherContents) ||
          !undergroundStorageTank.typeOfLeakDetection ||
          undergroundStorageTank.typeOfLeakDetection === "None" ||
          (undergroundStorageTank.typeOfLeakDetection === "Other" &&
            !undergroundStorageTank.otherTypeOfLeakDetection) ||
          !undergroundStorageTank.pipeLeakDetection ||
          undergroundStorageTank.deductible === "None" ||
          !undergroundStorageTank.deductible ||
          !undergroundStorageTank.retroactiveDateOnExistingPolicyUtc ||
          undergroundStorageTank.pipeLineConstruction === "None" ||
          !undergroundStorageTank.pipeLineConstruction ||
          !undergroundStorageTank.pipeAgeInNumber ||
          undergroundStorageTank.pipeAgeInNumber === 0 ||
          !undergroundStorageTank.tankTightnessTestPassed
        ) {
          pass = false;
        }

        return true;
      });
    }

    this.setState({
      error: !pass ? "Please complete all required fields" : "",
    });

    return pass;
  };

  validateStorageTanks = async () => {
    await this.setState({
      isLoading: true,
      isValidating: true,
    });

    if (await this.validatePage2RequiredFields()) {
      const result = await api.validateStorageTanks(
        this.state.currentApplicationId
      );

      if (!this.checkResult(result)) {
        return;
      }

      if (result?.succeeded) {
        history.push("/application/3");
      }
    }

    this.setState({
      isLoading: false,
    });
  };

  // Applications Page 3 -------------------------------------------------------------------
  getInsuranceFraudWarningStatements = async () => {
    const result = await api.getInsuranceFraudWarningStatements(
      this.state.currentApplicationId
    );

    if (!this.checkResult(result)) {
      return;
    }

    this.setState({
      insuranceFraudWarning: result,
    });
  };

  getRequirementQuestions = async () => {
    this.setState({
      isLoading: true,
    });

    const result = await api.getRequirementQuestions(
      this.state.currentApplicationId
    );

    if (!this.checkResult(result)) {
      return;
    }

    let requirementQuestions = [];
    const requirementQuestionsMap = new Map();

    result.questions.map((requirementQuestion) => {
      requirementQuestions.push(requirementQuestion.templateId);
      requirementQuestionsMap.set(
        requirementQuestion.templateId,
        requirementQuestion
      );
      return true;
    });

    await this.setState({
      requirementQuestions: requirementQuestions,
      requirementQuestionsMap: requirementQuestionsMap,
    });

    this.setState({
      isLoading: false,
    });
  };

  getEndorsementQuestion1Setting = async () => {
    this.setState({
      isLoading: true,
    });

    const result = await api.getEndorsementQuestion1Setting(
      this.state.currentApplicationId
    );

    if (!this.checkResult(result)) {
      return;
    }

    await this.setState({
      endorsementQuestion1: result,
      requestedCertificateForFinancialAssurance: result.boolNoOnly
        ? "false"
        : result.boolYesOnly
        ? "true"
        : this.state.requestedCertificateForFinancialAssurance,
      isLoading: false,
    });
  };

  updateRequirementQuestion = async (data) => {
    this.setState({
      isLoading: true,
    });

    const payload = {
      id: data.id,
      templateId: data.templateId,
      value: data.value,
    };

    const result = await api.updateRequirementQuestion(
      this.state.currentApplicationId,
      payload
    );

    if (!this.checkResult(result)) {
      return;
    }

    const requirementQuestionsMap = this.state.requirementQuestionsMap;
    const newRequirementQuestionsMap = update(requirementQuestionsMap, {
      [data.templateId]: {
        $set: {
          ...data,
          id: result.id,
          value: data.value,
        },
      },
    });

    await this.setState({
      isLoading: false,
      requirementQuestionsMap: newRequirementQuestionsMap,
    });

    this.validatePage3RequiredFields();

    return result.id;
  };

  updateEndorsementQuestion = async (data) => {
    this.setState({
      isLoading: true,
    });

    const payload = {
      //field present in request
      containsRequestedCertificateForFinancialAssuranceValue:
        data.requestedCertificateForFinancialAssurance !== undefined &&
        data.requestedCertificateForFinancialAssurance !== null
          ? true
          : false,

      containsNoticeOfCancellationEntity1Value:
        data.noticeOfCancellationEntity1 !== undefined ? true : false,

      containsNoticeOfCancellationEntity1AddressValue:
        data.noticeOfCancellationEntity1Address !== undefined ? true : false,

      containsNoticeOfCancellationEntity2Value:
        data.noticeOfCancellationEntity2 !== undefined ? true : false,

      containsNoticeOfCancellationEntity2AddressValue:
        data.noticeOfCancellationEntity2Address !== undefined ? true : false,

      containsTria: data.tria !== undefined ? true : false,
      //field values
      requestedCertificateForFinancialAssurance:
        data.requestedCertificateForFinancialAssurance === "yes"
          ? true
          : data.requestedCertificateForFinancialAssurance === "no"
          ? false
          : false,
      noticeOfCancellationEntity1: data.noticeOfCancellationEntity1
        ? data.noticeOfCancellationEntity1
        : null,
      noticeOfCancellationEntity1Address:
        data.noticeOfCancellationEntity1Address
          ? data.noticeOfCancellationEntity1Address
          : null,
      noticeOfCancellationEntity2: data.noticeOfCancellationEntity2
        ? data.noticeOfCancellationEntity2
        : null,
      noticeOfCancellationEntity2Address:
        data.noticeOfCancellationEntity2Address
          ? data.noticeOfCancellationEntity2Address
          : null,
      tria:
        data.tria === "yes" || data.tria === true
          ? true
          : data.tria === "no" || data.tria === false
          ? false
          : false,
    };

    const result = await api.updateEndorsementQuestion(
      this.state.currentApplicationId,
      payload
    );

    if (!this.checkResult(result)) {
      return;
    }

    if (data.requestedCertificateForFinancialAssurance) {
      await this.setState({
        requestedCertificateForFinancialAssurance:
          data.requestedCertificateForFinancialAssurance !== undefined
            ? data.requestedCertificateForFinancialAssurance
            : null,
      });
    }

    if (data.tria !== null && data.tria !== undefined) {
      await this.setState({
        tria: data.tria !== undefined ? data.tria : null,
      });
    }

    this.getTotal();
    this.validatePage3RequiredFields();

    this.setState({
      isLoading: false,
    });
  };

  addAdditionalInsured = async (type) => {
    this.setState({
      isLoading: true,
    });

    let payload = {
      name: "",
      type: type,
    };

    let result = await api.addNamedInsured(payload);

    if (!this.checkResult(result)) {
      return;
    }

    const additionalInsured =
      type === "Named"
        ? this.state.additionalNamedInsured
        : this.state.additionalInsured;
    const additionalInsuredMap =
      type === "Named"
        ? this.state.additionalNamedInsuredMap
        : this.state.additionalInsuredMap;

    const newAdditionalInsured = update(additionalInsured, {
      $add: [result.id],
    });
    const newAdditionalInsuredMap = update(additionalInsuredMap, {
      $add: [
        [
          result.id,
          {
            id: result.id,
            name: "",
            isTankOwner: "",
          },
        ],
      ],
    });

    await this.setState({
      ["additional" + (type === "Named" ? "Named" : "") + "Insured"]:
        newAdditionalInsured,
      ["additional" + (type === "Named" ? "Named" : "") + "InsuredMap"]:
        newAdditionalInsuredMap,
    });

    payload = {
      namedInsuredIds: [result.id],
    };

    result = await api.associateInsuredWithApplication(
      this.state.currentApplicationId,
      payload
    );

    if (!this.checkResult(result)) {
      return;
    }

    this.getTotal();
    this.validatePage3RequiredFields();

    this.setState({
      isLoading: false,
    });
  };

  updateAdditionalInsured = async (data) => {
    this.setState({
      isLoading: true,
    });

    const additionalInsuredMap =
      this.state[
        "additional" + (data.type === "Named" ? "Named" : "") + "InsuredMap"
      ];

    const newAdditionalInsuredMap = update(additionalInsuredMap, {
      [data.id]: {
        $set: {
          ...data,
          name: data.name,
          isTankOwner: data.isTankOwner,
        },
      },
    });

    await this.setState({
      ["additional" + (data.type === "Named" ? "Named" : "") + "InsuredMap"]:
        newAdditionalInsuredMap,
    });

    let payload = {
      name: data.name,
      addressLine1: data.addressLine1,
      addressLine2: data.addressLine2,
      city: data.city,
      state: data.state,
      postalCode: data.postalCode,
      type: data.type,
    };

    let result = await api.updateNamedInsured(data.id, payload);

    if (!this.checkResult(result)) {
      return;
    }

    payload = {
      isTankOwner: data.isTankOwner === "yes" ? true : false,
    };

    result = await api.assignTankOwner(data.id, payload);

    if (!this.checkResult(result)) {
      return;
    }

    this.setState({
      isLoading: false,
    });

    this.validatePage3RequiredFields();
  };

  deleteAdditionalInsured = async (id, type) => {
    this.setState({
      isLoading: true,
    });

    const result = await api.deleteAdditionalInsured(
      this.state.currentApplicationId,
      [id]
    );

    if (!this.checkResult(result)) {
      return;
    }

    const additionalInsured =
      type === "Named"
        ? this.state.additionalNamedInsured
        : this.state.additionalInsured;
    const additionalInsuredMap =
      type === "Named"
        ? this.state.additionalNamedInsuredMap
        : this.state.additionalInsuredMap;

    const newAdditionalInsured = update(additionalInsured, { $remove: [id] });
    const newAdditionalInsuredMap = update(additionalInsuredMap, {
      $remove: [id],
    });

    await this.setState({
      ["additional" + (type === "Named" ? "Named" : "") + "Insured"]:
        newAdditionalInsured,
      ["additional" + (type === "Named" ? "Named" : "") + "InsuredMap"]:
        newAdditionalInsuredMap,
    });

    this.getTotal();
    this.validatePage3RequiredFields();

    this.setState({
      isLoading: false,
    });
  };

  addEntity = async () => {
    if (!this.state.entity1) {
      this.setState({
        entity1: {
          name: "",
          mailingAddress: "",
        },
      });
    } else {
      this.setState({
        entity2: {
          name: "",
          mailingAddress: "",
        },
      });
    }

    this.validatePage3RequiredFields();
  };

  updateEntity = async (data) => {
    await this.setState({
      ["entity" + data.index]: {
        name: data.name,
        mailingAddress: data.mailingAddress,
      },
    });

    const payload = {
      ["noticeOfCancellationEntity" + data.index]: data.name,
      ["noticeOfCancellationEntity" + data.index + "Address"]:
        data.mailingAddress,
    };

    await this.updateEndorsementQuestion(payload);
  };

  deleteEntity = async (index) => {
    let entity2 = {};

    if (index === 1) {
      entity2 = this.state.entity2;
    }

    await this.setState({
      entity1: index === 2 ? this.state.entity1 : false,
      entity2: false,
    });

    if (index === 1) {
      this.setState({
        entity1: entity2,
      });
    }

    const payload = {
      ["noticeOfCancellationEntity" + index]: null,
      ["noticeOfCancellationEntity" + index + "Address"]: null,
    };

    this.updateEndorsementQuestion(payload);
  };

  updateSignature = async (data) => {
    const signature = {
      name: data.name,
      title: data.title,
      signature: data.signature,
    };

    await this.setState({
      signature: signature,
    });

    if (this.state.isValidating) {
      this.validatePage3RequiredFields();
      this.validateBindRequiredFields();
    }
  };

  //submit page 3
  submitQuestions = async () => {
    await this.setState({
      isLoading: true,
      isValidating: true,
      error: "",
    });

    const isValid = await this.validatePage3RequiredFields();

    if (isValid) {
      await this.setState({
        isLoading: true,
      });

      let result = await api.validateEffectiveDate(
        this.state.currentApplicationId
      );

      if (result?.succeeded === false) {
        alert("Effective date cannot be predated.");
        history.push("/application/1");
        await this.setState({
          isLoading: false,
        });
        return false;
      }

      const payload = {
        name: this.state.signature.name,
        title: this.state.signature.title,
        signature: this.state.signature.signature,
        date: this.state.signature.date,
      };

      result = await api.signApplication(
        this.state.currentApplicationId,
        payload
      );

      if (!this.checkResult(result)) {
        return;
      }

      result = await api.completeApplication(this.state.currentApplicationId);

      if (!this.checkResult(result)) {
        return;
      }

      if (result?.succeeded) {
        await this.setState({
          isLoading: false,
          error: "",
          status: result.status,
          premium: result.premium,
          triaCost: result.triaCost,
          assessmentsAndSurcharges: result.assessmentsAndSurcharges,
          total: result.total,
        });

        history.push("/application/status");
      }
    }
  };

  validatePage3RequiredFields = async () => {
    if (!this.state.isValidating) {
      return;
    }

    await this.setState({
      isLoading: true,
      isValidating: true,
    });

    let pass = true;

    this.state.requirementQuestions.map((requirementQuestion) => {
      if (
        this.state.requirementQuestionsMap.get(requirementQuestion).value ===
          null ||
        this.state.requirementQuestionsMap.get(requirementQuestion).value === ""
      ) {
        pass = false;
      }
      return true;
    });

    if (pass && this.state.requestedCertificateForFinancialAssurance === null) {
      pass = false;
    }

    if (pass && this.state.tria === null) {
      pass = false;
    }

    if (pass) {
      Array.from(this.state.additionalNamedInsured).map(
        (additionalNamedInsured) => {
          if (
            !this.state.additionalNamedInsuredMap.get(additionalNamedInsured)
              .name ||
            !this.state.additionalNamedInsuredMap.get(additionalNamedInsured)
              .addressLine1 ||
            !this.state.additionalNamedInsuredMap.get(additionalNamedInsured)
              .city ||
            !this.state.additionalNamedInsuredMap.get(additionalNamedInsured)
              .state ||
            !this.state.additionalNamedInsuredMap.get(additionalNamedInsured)
              .postalCode ||
            this.state.additionalNamedInsuredMap.get(additionalNamedInsured)
              .isTankOwner === null
          ) {
            pass = false;
          }
          return true;
        }
      );
    }

    if (pass) {
      Array.from(this.state.additionalInsured).map((additionalInsured) => {
        if (
          !this.state.additionalInsuredMap.get(additionalInsured).name ||
          this.state.additionalInsuredMap.get(additionalInsured).isTankOwner ===
            null
        ) {
          pass = false;
        }
        return true;
      });
    }

    if (pass) {
      if (
        this.state.entity1 &&
        (!this.state.entity1.name || !this.state.entity1.mailingAddress)
      ) {
        pass = false;
      }
    }

    if (pass) {
      if (
        this.state.entity2 &&
        (!this.state.entity2.name || !this.state.entity2.mailingAddress)
      ) {
        pass = false;
      }
    }

    if (pass && getSiteType() === "Agency") {
      const signature = this.state.signature;
      if (!signature.name || !signature.title || !signature.signature) {
        pass = false;
      }
    }

    if (!pass) {
      this.setState({
        isLoading: false,
        error: "Please complete all required fields",
      });
      return false;
    } else {
      await this.setState({
        isValidating: false,
        isLoading: false,
        error: "",
      });

      return true;
    }
  };

  // Total
  getTotal = async () => {
    const result = await api.getTotal(this.state.currentApplicationId);

    if (!this.checkResult(result)) {
      return;
    }

    this.setState({
      total:
        result.calculationType !== "UnderwriterReferral" &&
        result.total === "$0"
          ? "n/a"
          : result.total,
    });
  };

  // Clone Application
  cloneApplication = async (id) => {
    const result = await api.cloneApplication(id);
  };
}

export default ApplicationsContainer;
